import React, { useEffect, useRef, useState } from 'react';
import './index.css';

export interface Segment {
  value: string;
  label: string;
  ref: any;
}
export interface SegmentedControlProps {
  segments: Segment[];
  onChange: (value: string) => void;
  controlRef: any;
}

const SegmentedControl = (props: SegmentedControlProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { segments, onChange, controlRef } = props;
  const componentReady = useRef(false);

  const onInputChange = (value: string, index: number) => {
    setActiveIndex(index);
    onChange(value);
  };

  useEffect(() => {
    componentReady.current = true;
  }, []);

  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref;
    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;

    style.setProperty('--highlight-width', `${offsetWidth as number}px`);
    style.setProperty('--highlight-x-pos', `${offsetLeft as number}px`);
  }, [activeIndex, controlRef, segments]);

  return (
    <div className='.controls-container' ref={controlRef}>
      <div className={`controls ${componentReady.current ? 'ready' : 'idle'}`}>
        {segments.map((segment, index) => {
          return (
            <div
              key={segment.value}
              className={`segment ${
                index === activeIndex ? 'active' : 'inactive'
              }`}
              ref={segment.ref}
            >
              <input
                type='radio'
                value={segment.value}
                id={segment.label}
                name={segment.label}
                checked={index === activeIndex}
                onChange={() => {
                  onInputChange(segment.value, index);
                }}
              />
              <label htmlFor={segment.label} className='p-1'>
                {segment.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { SegmentedControl };
