import React from 'react';
import { EtfList } from './component/EtfList';
import data from './data/data.json';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-HCKFBLQ6PK');
ReactGA.send('pageview');

function App () {
  return (
    <div className='dark:bg-gray-900 min-h-screen bg-gray-100'>
      <nav className='border-gray-200 px-4 sm:px-4 py-2.5'>
        <div className='flex flex-wrap items-center justify-between h-12'>
          <div className='flex'>
            <img
              src='/zoo.png'
              className='h-6 mr-1 sm:h-9'
              alt='ETF Zoo Logo'
            />
            <span className='self-center text-xl font-semibold whitespace-nowrap dark:text-white'>
              ETF动物园
            </span>
          </div>
        </div>
      </nav>

      <EtfList stocks={data} />
    </div>
  );
}

export default App;
