import axios from 'axios';
import { type StockLive } from '../data/Stock';

const EASTMONEY_URL = 'http://push2his.eastmoney.com';
async function getStock (code: string): Promise<StockLive> {
  return await axios
    .get(`${EASTMONEY_URL}/api/qt/stock/get`, {
      params: {
        fields1: 'f1,f2,f3,f4,f5,f6,f7,f8,f9,f10,f11',
        fields2: 'f43,f51,f53,f56,f58',
        secid: code,
        ndays: 1,
        iscr: 0,
        _: Date.now(),
      },
      responseType: 'json',
    })
    .then((rawResponse) => {
      const { data } = rawResponse.data;

      return {
        code: data.f57,
        name: data.f58,
        preClose: data.f60 / Math.pow(10, data.f59),
        currentPrice: data.f43 / Math.pow(10, data.f59),
      };
    });
}

export { getStock };
