import { type StockData } from '../../data/Stock';
import React from 'react';

interface EtfItemProps {
  stock: StockData;
}

const EtfItem: React.FC<EtfItemProps> = (props: EtfItemProps) => {
  const { stock } = props;

  return (
    <div className='bg-white rounded shadow-md flex flex-col items-start p-4 dark:bg-white'>
      <div className='self-center text-xl font-medium text-red-600'>
        {stock?.name}
      </div>
      <div className='self-center text-sm'>
        {stock?.marketName}
        {stock?.code}
      </div>
      {Boolean(stock.currentPrice) && (
        <div className='self-center text-base'>当前：{stock.currentPrice}</div>
      )}

      {Boolean(stock?.support) && (
        <div className='grid grid-cols-2 w-full'>
          <div>支撑点位：{stock.support}</div>
          <div>
            距离支撑：
            {stock.distanceFromSupport?.toFixed(2)}%
          </div>
        </div>
      )}

      {Boolean(stock?.pressure) && (
        <div className='grid grid-cols-2 w-full'>
          <div>压力点位：{stock.pressure}</div>
          <div>
            距离压力：
            {stock.distanceFromPressure?.toFixed(2)}%
          </div>
        </div>
      )}
    </div>
  );
};

export { EtfItem };
