import React, { useCallback, useEffect, useRef, useState } from 'react';
import { type StockData, type StockMeta } from '../../data/Stock';
import { getAllStockData } from '../../service/StockService';
import { percentageDistance } from '../../util';
import { SegmentedControl } from '../SegmentedControl';
import { EtfItem } from './EtfItem';
import './index.css';

interface EtfListProps {
  stocks?: StockMeta[];
}

type StockCompareFunction = (a: StockData, b: StockData) => number;

const EtfList: React.FC<EtfListProps> = (props: EtfListProps) => {
  const [stockDataList, setStockDataList] = useState<StockData[]>([]);

  const undervaluationFirst = useCallback<StockCompareFunction>((a, b) => {
    return (b.distanceFromSupport ?? 0) - (a.distanceFromSupport ?? 0);
  }, []);

  const overvaluationFirst = useCallback<StockCompareFunction>((a, b) => {
    return (a.distanceFromSupport ?? 0) - (b.distanceFromSupport ?? 0);
  }, []);

  const [compareFunction, setCompareFunction] = useState<StockCompareFunction>(
    () => undervaluationFirst
  );

  const { stocks } = props;

  useEffect(() => {
    stocks != null &&
      getAllStockData(stocks)
        .then((data) => {
          return data.map((stock) => {
            return {
              ...stock,
              distanceFromPressure: percentageDistance(
                stock.currentPrice,
                stock.pressure ?? 0
              ),
              distanceFromSupport: percentageDistance(
                stock.currentPrice,
                stock.support ?? 0
              ),
            };
          });
        })
        .then((data) => {
          setStockDataList(data);
          return data;
        })
        .catch(() => null);
  }, [stocks]);

  return (
    <div className='flex flex-col px-4 pb-4'>
      <div className='text-black dark:text-white self-center lg:self-end'>
        <SegmentedControl
          controlRef={useRef()}
          onChange={(s) => {
            if (s === 'undervaluation') {
              setCompareFunction(() => undervaluationFirst);
            } else if (s === 'overvaluation') {
              setCompareFunction(() => overvaluationFirst);
            }
          }}
          segments={[
            { value: 'undervaluation', label: '低估优先', ref: useRef() },
            { value: 'overvaluation', label: '高估优先', ref: useRef() },
          ]}
        />
      </div>

      <div className='grid grid-cols-1 gap-4 md:grid-co ls-3 lg:grid-cols-4 py-4'>
        {stockDataList?.sort(compareFunction).map((stock) => {
          return (
            <EtfItem
              stock={stock}
              key={`${stock.marketCode}${stock.code}${stock.currentPrice}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export { EtfList };
