import { getStock } from '../common/request';
import { type StockData, type StockMeta } from '../data/Stock';

function getSecurityId (stock: StockMeta): string {
  return `${stock.marketCode}.${stock.code}`;
}

async function getStockData (metaData: StockMeta): Promise<StockData> {
  return await getStock(getSecurityId(metaData)).then((liveData) => {
    return { ...metaData, ...liveData };
  });
}

async function getAllStockData (metaDataArray: StockMeta[]): Promise<StockData[]> {
  const promises = metaDataArray.map(async (metaData) => await getStockData(metaData));

  return await Promise.all(promises);
}

export { getStockData, getAllStockData };
